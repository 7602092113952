/*
* Menu Dla Produktów
*/
function productTabs() {
 let categoryList = document.querySelector('.category-list');
 if (!categoryList) {
  return
 }
 let categoryListWithChild = categoryList.querySelectorAll('.has-children');
 let activator = categoryList.querySelectorAll('.activator');
 for (let i = 0; i < activator.length; i++) {
  activator[i].addEventListener('click', function () {
   categoryListWithChild[i].classList.toggle('open');
  })
 }
}
productTabs()