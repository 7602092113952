/*
* Scroll to tabs
*/
function scrollToTabs() {
 let buttonScroll = document.querySelector('.product-features A');
 if (!buttonScroll) {
  return
 }
 let elementScroll;
 buttonScroll.addEventListener('click', function (e) {
  e.preventDefault();
  elementScroll = buttonScroll.getAttribute('href');
  let el = document.querySelector(`${elementScroll}`)
  el.scrollIntoView({ behavior: 'smooth', block: 'start' });
 })
}
scrollToTabs()