/*
* Info Prod Tabs
*/
function photoSwap() {
 let listImage = document.querySelector('.thumbnails-list');
 let bigImageContainer = document.querySelector('.product-image');
 if (!listImage || !bigImageContainer) {
  return
 }
 let bigImageList = bigImageContainer.querySelectorAll('.product-image LI');
 let listItem = listImage.querySelectorAll('.thumbnail');
 let hrefImage;
 for (let i = 0; i < listItem.length; i++) {
  listItem[i].addEventListener('mouseenter', function (e) {
   e.preventDefault();
   hrefImage = listItem[i].getAttribute('data-image-small');

   bigImageList.forEach(element => {
    element.classList.remove('active');
   })

   bigImageContainer.querySelector(`LI[data-image-big="${hrefImage}"]`).classList.add('active');
  })
 }
}
photoSwap()
