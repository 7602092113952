/*
* Footer Button Show
*/
function showAndScroll() {
 let footerButton = document.querySelector('.scroll_top');
 let offsetScroll;
 if (!footerButton) {
  return
 }

 footerButton.addEventListener('click', function (e) {
  e.preventDefault();
  window.scrollTo({
   top: 0,
   behavior: "smooth"
  });
 })

 window.addEventListener('scroll', function () {
  offsetScroll = this.scrollY;
  if (offsetScroll > 150) {
   footerButton.style.opacity = '1';
  } else {
   footerButton.style.opacity = '0';
  }
 })
}
showAndScroll()
