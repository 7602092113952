/*
* Dodatkowe Pola Dla Form
*/
function hideFormContent() {
 let form = document.querySelector('.wpcf7-form');
 if (!form) {
  return
 }
 let hideContent = form.querySelector('.form_hide_content .wpcf7-select');
 let content = form.querySelector('.form_content_hidden');
 if (hideContent.selectedIndex !== 0) {
  content.classList.add('active');
 } else {
  content.classList.remove('active');
 }

 hideContent.addEventListener('change', function (e) {
  if (hideContent.selectedIndex !== 0) {
   content.classList.add('active');
  } else {
   content.classList.remove('active');
  }
 })
}
hideFormContent()
