/*
* Slider Mobile
*/
function mobileAutoSlider() {
 let mobileView = window.matchMedia('(max-width: 769px)');
 if (!mobileView.matches) {
  return
 }

 let slider = document.querySelector('.slider');
 if (!slider) {
  return
 }
 let sliderList = slider.querySelector('.slider_list');
 let slides = slider.querySelectorAll('.slider_list LI');
 let slideWidth = slider.querySelector('.slider_list LI').getBoundingClientRect().width;
 let prevBtn = slider.querySelector('.prev');
 let nextBtn = slider.querySelector('.next');
 let currentSlide = 0;
 let stopSlide = false;

 function moveSlide(count) {
  sliderList.style.transform = `translateX(${-slideWidth * count}px)`
 }

 nextBtn.addEventListener('click', function (e) {
  e.preventDefault();
  currentSlide++;

  stopSlide = true;

  if (currentSlide > slides.length - 1) {
   currentSlide = 0;
  }

  moveSlide(currentSlide)
 })

 prevBtn.addEventListener('click', function (e) {
  e.preventDefault();
  currentSlide--;

  stopSlide = true;

  if (currentSlide < 0) {
   currentSlide = slides.length - 1;
  }

  moveSlide(currentSlide)
 })

 setInterval(function () {
  if (stopSlide === false) {
   currentSlide++;

   if (currentSlide > slides.length - 1) {
    currentSlide = 0;
   }

   moveSlide(currentSlide);
  } else {
   stopSlide = false;
  }
 }, 6000)
}
mobileAutoSlider()