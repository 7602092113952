/*
* Info Prod Tabs
*/
function tabsInfo() {
 let tabs = document.querySelectorAll('.product-info-nav-list LI A');
 if (!tabs.length) {
  return
 }
 let tabsContent = document.querySelectorAll('.product-info-list LI');
 let tabHref;
 tabs.forEach(tab => {
  tab.addEventListener('click', function (e) {
   e.preventDefault();
   tabs.forEach(activetab => {
    activetab.parentElement.classList.remove('active');
   })
   tab.parentElement.classList.add('active');

   tabHref = tab.getAttribute('href');
   tabsContent.forEach(activetab => {
    activetab.classList.remove('active');
   })
   document.querySelector(`${tabHref}`).classList.add('active');
  })
 })
}
tabsInfo()